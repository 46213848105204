import {Component, ElementRef, Input, ViewChild} from '@angular/core'
import {DialogRef} from '@angular/cdk/dialog'

@Component({
  standalone: true,
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {

  @Input() contentClass = 'max-w-lg p-6'
  @Input() closable = true

  @ViewChild('content') content: ElementRef<HTMLDivElement>

  constructor(
    private dialogRef: DialogRef<void>,
  ) {
  }

  click(e?: any) {
    if (!this.closable) return
    if (e && this.content.nativeElement.contains(e.target)) return
    this.hide()
  }

  hide() {
    this.dialogRef.close()
  }
}
